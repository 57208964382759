<template>
  <div class="Test">
    <div class="ul_main" v-if="info && info.openid">
      <div><span>unionid:</span> {{info.unionid}}</div>
      <div><span>openid:</span> {{info.openid}}</div>
      <div><span>nickname:</span> {{info.nickname}}</div>
      <div><span>headimgurl:</span> {{info.headimgurl}}</div>
      <div><span>province:</span> {{info.province}}</div>
      <div><span>city:</span> {{info.city}}</div>
      <div><span>country:</span> {{info.country}}</div>
      <div><span>sex:</span> {{info.sex}}</div>
      <div><span>privilege:</span> {{info.privilege}}</div>
      <div><span>language:</span> {{info.language}}</div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
export default {
  name: "Test",
  data() {
    return {
      reload: 0,
      info: {},
      iswx: window.isWeixin(),
    };
  },
  mounted() {
    this.iswx = window.isWeixin();
    this.reload = localStorage.getItem('testReload') ? localStorage.getItem('testReload') : 0;
    console.log(this.iswx, this.reload, "ssssss");
    if (this.iswx) { // 在微信中
      // 用户授权后获取code值
      this.code = this.$route.query.code ? this.$route.query.code : null;
      if (this.code) {
        this.reload = 1;
      }else {
        this.reload = 0;
      }
      if (this.reload == 1 && this.code) {
        this.getwxlog(this.code);
      }else {
        console.log("this.getwxinfo()");
        this.getwxinfo(); // 自动获取并保存新用户信息
      }
    }
    console.log("this.mounted()");
  },
  activated() {
    console.log("ddd");
  },
  methods: {
    /* 判断是否在微信中打开，如在微信中打开获取openid或用户信息 */
    getwxinfo() {
      let _that = null;
      _that = this;
      /* 以下下是获取openID 以及判断用户是否在微信中打开页面 */
      if (!window.isWeixin()) {
        this.reload = 1;
        this.iswx = false; 
      }
      
      console.log(this.reload, "this.reload", this.iswx);
      /* 在微信中打开页面 */
      if (this.reload != 1 && this.iswx) { 
        this.iswx = true; 
        this.reload = 1; 
        localStorage.setItem("testReload", 1);
        const wxAuthUrl2 = 
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc465682b705715e5&redirect_uri=" + encodeURIComponent(window.location.href) + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
          // 传到后台得到openid, 其中access_token 2小时过期
          if (_that.code == null || _that.code === "") {
            window.location.href = wxAuthUrl2; // [自刷新当前页]
          }
      } else {
        // localStorage存在openid || 本页面不在公众号中打开
        console.log("localStorage存在openid || 本页面不在公众号中打开");
      }
    },
    // 获取用户微信信息
    getwxlog(code) {
      let _that = null;
      _that = this;
      const toast = this.$toast({
        message: '加载中...',
        type: 'loading',
        duration: 0
      })
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/wetch/wx_oauth2",
          _that.$qs.stringify({
            code: code
          })
        )
        .then(function(response) {
          if (response.data) {
            _that.$axios
              .post(
                _that.$store.state.domain + "web/wetch/get_wxsnsinfo",
                _that.$qs.stringify({
                  openid: response.data
                })
              )
              .then(res => {
                toast.clear();
                localStorage.setItem("testReload", 2);
                if (res.data && res.data.headimgurl) {
                  _that.info = res.data;
                }
                
              })
              .catch(error => {
                console.log(error);
              })
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
  }
};
</script>

<style lang="less">
.Test {
  overflow-x: hidden;
  position: relative;
  background: #ededed;
  .ul_main {
    padding: 10px;
    word-wrap: break-word;
    text-align: left;
    span {
      font-weight: bold;
    }
  }
}
</style>
